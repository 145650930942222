import { useEffect, useState } from 'react'

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone'

// Partials
import Btn from '/views/partials/btn'
import { Input, Form, FormFeedback } from '/views/partials/forms'

// Sections
import PinInput from '/views/sections/pin-input'

// Services
import userServices from '/services/user'

// Context
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals'

// Static Assets
// const image_icon_apple = '/assets/images/icons/apple.svg'
// const image_icon_google = '/assets/images/icons/google.svg'

const usePrettyPhoneNumber = (phoneNumber) => {
	const [prettyPhoneNumber, setPrettyPhoneNumber] = useState('');

	useEffect(() => {
		setPrettyPhoneNumber((phoneNumber && phoneNumber.length > 0) ? formatPhone(phoneNumber) : '');
	}, [phoneNumber])

	return prettyPhoneNumber
}

const Login = ({ onSuccess }) => {
	const { openModal } = useModals();
	const { softLogin } = useAuth();

	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [feedbackData, setFeedbackData] = useState(false);

	const [phoneNumber, setPhoneNumber] = useState('');

	const prettyPhoneNumber = usePrettyPhoneNumber(phoneNumber);

	// Pin
	const [pin, setPin] = useState('');
	const [pinTimerRef, setPinTimerRef] = useState(0);
	const [pinError, setPinError] = useState(false);
	const [disablePin, setDisablePin] = useState(false)

	const sendCode = (phone) => {
		setDisablePin(true);
		setFeedbackData(false);
		setLoading(true);
		setPin('');

		userServices.sendOTP(phone ? phone : phoneNumber).then(() => {
			setPinTimerRef(pinTimerRef + 1);
			setStep(2);
		}).catch((message) => {
			setFeedbackData(message)
		}).finally(() => {
			setLoading(false);
			setDisablePin(false);
		});
	}

	const submitPhone = (formFields) => {
		setPhoneNumber(formFields.mobile_number);
		sendCode(formFields.mobile_number);
	}

	const submitPin = () => {
		if (pin.length === 4) {
			setLoading(true);
			userServices.checkOTP(phoneNumber, pin).then((token) => {
				userServices.authenticateOTP(phoneNumber, token).then((authToken) => {
					softLogin(authToken).then(() => {
						userServices.checkAgreementUpdates().then((payload) => {
							if(payload.length) {
								openModal('agreementUpdate', { agreements: payload });
							}
						}).catch(() => {
						}).finally(() => {
							onSuccess();
						})
					}).catch((message) => {
						setStep(1);
						setLoading(false);
						setFeedbackData(message);
					})
				}).catch((message) => {
					setLoading(false);
					setFeedbackData(message);
				})
			}).catch((message) => {
				setLoading(false);
				setFeedbackData(message);
			})
		}
		else {
			setPinError(true);
		}
	}

	return (
		<div className="auth-login">
			{step === 1 &&
				<Form className="login-step login-entry step-1" onSubmit={submitPhone}>
					<FormFeedback className="entry-error" data={feedbackData} />
					<h1 className="entry-title">Lütfen cep telefon numaranızı giriniz.</h1>
					<p className="entry-subtitle">Babymall.com.tr’ye kayıtlı cep telefonunuza gelen onay kodu ile giriş yapabilirsiniz.</p>

					<Input
						type="phone"
						name="mobile_number"
						id="login_mobile_number"
						mask="phone"
						validation={{
							required: 'Bir telefon numarası girmelisiniz.',
							phone: 'Geçerli bir telefon numarası girmelisiniz.',
						}}
						autoComplete="tel"
						label="Cep Telefonu" />

					<Btn
						loading={loading}
						disabled={loading}
						type="submit"
						className="entry-submit primary big block">
						Gönder
					</Btn>

					{/* <div className="auth-social">
						<span className="social-seperator"><span>veya</span></span>
						<Btn
							disabled={loading}
							icon={<Image className="btn-icon" src={image_icon_apple} />}
							className="social-btn apple outline">
							Apple <br />Hesabımla
						</Btn>
						<Btn
							disabled={loading}
							icon={<Image className="btn-icon" src={image_icon_google} />}
							className="social-btn google outline full">
							Google Hesabımla
						</Btn>
					</div> */}
				</Form>
			}
			{step === 2 &&
				<Form className="login-step login-otp step-2" onSubmit={submitPin}>
					<Btn
						className="otp-backbtn smaller outline"
						onClick={() => { setStep(1); }}
						icon="arrow-left">
						geri
					</Btn>

					<FormFeedback className="otp-error" data={feedbackData} />

					<h1 className="otp-title">SMS Onayı</h1>
					<p className="otp-subtitle">Lütfen <strong>{prettyPhoneNumber}</strong> nolu telefonunuza SMS ile gönderilen doğrulama kodunu giriniz.</p>

					<PinInput
						className="auth-pininput otp-pin"
						pin={pin}
						disabled={disablePin || loading}
						error={pinError}
						onChange={setPin}
						pinRef={pinTimerRef}
						onResend={sendCode}
						onComplete={() => { setPinError(false); submitPin(); }} />

					<Btn
						loading={loading}
						disabled={loading}
						type="submit"
						className="otp-submit block primary big">
						Devam Et
					</Btn>
				</Form>
			}
		</div>
	)
}

export default Login