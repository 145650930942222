import '/assets/styles/pages/auth.scss';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Btn from '/views/partials/btn'

// Sections
import Login from '/views/sections/auth-login'
import Register from '/views/sections/auth-register'

// Partials


// Services
// import userServices from '/services/user';

// Context
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Static Assets
import image_logo from '/assets/images/logo.svg';

const Auth = () => {
	const { getUserStorageData, loggedIn, initialized, isGuest } = useAuth();
	const { mobile } = useBreakpoints();
	const { redirect, activeRoute } = useNavigator();
	const { setError } = useGlobalState();
	const { redirect: redirectParam } = useParams(['login', 'register']);

	const mode = activeRoute?.key;

	useEffect(() => {
		if(initialized && loggedIn && !isGuest) {
			redirect('account.info', {}, { replace: true });
		}
	}, [initialized, loggedIn, isGuest])

	const successAction = () => {
		getUserStorageData().catch(() => {
			setError(true);
		}).then(() => {
			redirect(redirectParam ?? 'home');
		})
	}

	return (
		<main className="page auth">
			<div className="portlet section auth">
				{mobile &&
					<AuthNav />
				}
				<header className="auth-header">
					<Link href="home" className="header-logo">
						<Img src={image_logo} className="logo-image" />
					</Link>

					<Icon name="secure" />

					<nav className="header-nav">
						<Link
							className={'nav-link' + (mode === 'login' ? ' active' : '')}
							href="login">
							Giriş Yap
						</Link>
						<Link
							className={'nav-link' + (mode === 'register' ? ' active' : '')}
							href="register">
							Kayıt Ol
						</Link>
					</nav>
				</header>
				{mode === 'login' ?
					<Login onSuccess={successAction} />
					:
					<Register onSuccess={successAction} />
				}
			</div>
		</main>
	)
}

const AuthNav = () => {
	const history = useHistory();
	const { redirect } = useNavigator();

	const goBack = () => {
		if(window.$babyMall?.prevLocation?.origin === window.$babyMall?.curLocation?.origin) {
			history.goBack();
		}
		else {
			redirect('home');
		}
	}

	return (
		<nav className="auth-nav">
			<Btn className="smaller nude" onClick={goBack} icon="arrow-left">Geri dön</Btn>
		</nav>
	)
}

Auth.defaultProps = {
	mode: 'login'
}

export default Auth;