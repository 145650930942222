// Deps
import { /*useRef,*/ useState, useEffect } from 'react'
// import { sendGtagEvent } from '/utilities/analytics-controller'

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone'

// Partials
import Btn from '/views/partials/btn'
import Link from '@wearetla/tla-essentials-tools/partials/link';
import { Input, Form, FormFeedback } from '/views/partials/forms'

// Sections
import PinInput from '/views/sections/pin-input'

// Services
import userServices from '/services/user'

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';

// Static Assets
// const image_icon_apple = '/assets/images/icons/apple.svg'
// const image_icon_google = '/assets/images/icons/google.svg'

const Register = ({ onSuccess }) => {
	const { openModal } = useModals();
	const { softLogin } = useAuth();

	const [step, setStep] = useState(3);
	const [loading, setLoading] = useState(false);
	const [feedbackData, setFeedbackData] = useState(false);

	const [userFormData, setUserFormData] = useState({});

	const [submittedUserData, setSubmittedUserData] = useState({});
	
	// Pin
	const [pin, setPin] = useState('');
	const [pinTimerRef, setPinTimerRef] = useState(0);
	const [pinError, setPinError] = useState(false);
	const [disablePin, setDisablePin] = useState(false)

	useEffect(() => {
		setFeedbackData(false);
		setPinError(false);
	}, [step])

	const sendCode = (phone) => {
		setDisablePin(true);
		setLoading(true);
		setFeedbackData(false);
		setPin('');
		
		userServices.sendOTP(phone ? phone : submittedUserData.mobile_number, 'register').then(() => {
			setPinTimerRef(pinTimerRef + 1);
			setStep(4);
		}).catch((message) => {
			setFeedbackData(message);
		}).finally(() => {
			setLoading(false);
			setDisablePin(false);
		});
	}

	const submitUser = (formFields) => {
		setSubmittedUserData({
			...formFields,
			pretty_mobile_number: formatPhone(formFields.mobile_number),
		});
		sendCode(formFields.mobile_number);
	}

	const submitPin = () => {
		if (pin.length === 4) {
			setLoading(true);

			userServices.checkOTP(submittedUserData.mobile_number, pin).then((token) => {
				const agreements = [];

				const {
					agreement_usage_terms,
					notification_email,
					notification_sms,
					notification_call, // Not active
					agreement_kvkk,
					...userData
				} = submittedUserData;

				if (agreement_usage_terms) {
					agreements.push('usage_terms');
				}
				if (notification_email || notification_sms || notification_call) {
					agreements.push('commercial');
				}
				if (agreement_kvkk) {
					agreements.push('consent');
					agreements.push('kvkk');
				}

				const submitData = {
					...userData,
					notification_email: notification_email === '1' ? true : false,
					notification_sms: notification_sms === '1' ? true : false,
					// notification_call: notification_call ? true : false,
					type: null,
					otp_token: token,
					agreements: agreements,
				}

				userServices.register(submitData).then((authToken) => {
					softLogin(authToken).then(() => {
						onSuccess();
					}).catch((message) => {
						setLoading(false);
						setFeedbackData(message);
					})
					userServices.setAuthToken(authToken);

					// sendGtagEvent({
					// 	event: 'conversion',
					// 	sendTo: 'AW-381727730/-BngCL-7pbsCEPLngrYB',
					// 	value: 1.0,
					// });
				}).catch((message) => {
					setLoading(false);
					setFeedbackData(message);
				});
			}).catch((message) => {
				setLoading(false);
				setFeedbackData(message);
			})
		}
		else {
			setPinError(true);
		}
	}

	return (
		<div className="auth-register">
			{step === 3 &&
				<Form
					onSubmit={submitUser}
					className="register-step3 register-user">
					{/* <div className="auth-social">
						<span className="social-seperator"><span>hızlı giriş için hesabımı bağla</span></span>
						<Btn
							disabled={loading}
							icon={<Image className="btn-icon" src={image_icon_apple} />}
							className="social-btn apple outline">
							Apple <br />Hesabımla
						</Btn>
						<Btn
							disabled={loading}
							icon={<Image className="btn-icon" src={image_icon_google} />}
							className="social-btn google outline">
							Google <br />Hesabımla
						</Btn>
					</div> */}

					<div className="user-form">
						<FormFeedback
							focusOnMessage
							displayFormValidation
							genericValidationMessage="Lütfen eksik ve hatalı alanları doldurun."
							className="entry-error"
							data={feedbackData} />
						<Input
							disabled={loading}
							type="text"
							name="full_name"
							id="register_full_name"
							className="form-field"
							autoComplete="name"
							label="Adınız & Soyadınız"
							value={userFormData.full_name ? userFormData.full_name : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									full_name: val,
								})
							}}
							validation={{
								required: 'Bir ad ve soyad girmelisiniz.',
								fullName: 'Geçerli bir ad ve soyad girmelisiniz.',
							}} />

						<Input
							disabled={loading}
							type="phone"
							name="mobile_number"
							id="register_mobile_number"
							className="form-field"
							autoComplete="tel"
							label="Cep Telefonu"
							value={userFormData.mobile_number ? userFormData.mobile_number : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									mobile_number: val,
								})
							}}
							validation={{
								required: 'Bir ad ve soyad girmelisiniz.',
								phone: 'Geçerli bir telefon girmelisiniz.',
							}} />

						<Input
							disabled={loading}
							type="email"
							name="email"
							id="register_email"
							className="form-field"
							autoComplete="email"
							label="E-Posta Adresiniz"
							value={userFormData.email ? userFormData.email : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									email: val,
								})
							}}
							validation={{
								required: 'Bir ad ve soyad girmelisiniz.',
								email: 'Geçerli e-posta girmelisiniz.',
							}} />

						<Input
							disabled={loading}
							type="checkbox"
							className="form-field multi-line"
							validation={{
								required: 'Üye olmak için üyelik sözleşmesini onaylamalısınız.',
							}}
							label={
								<>
									<button type="button" className="link" onClick={() => { openModal('agreement', { agreement: 'usage_terms' }); }}>Üyelik Sözleşmesini</button> okudum, onaylıyorum.
								</>
							}
							defaultValue="1"
							checked={userFormData.agreement_usage_terms ? true : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									agreement_usage_terms: val ? true : false,
								})
							}}
							name="agreement_usage_terms"
							id="register_agreement_usage_terms" />
							
						<Input
							disabled={loading}
							type="checkbox"
							className="form-field multi-line"
							validation={{
								required: 'Üye olmak için açık rıza metnini onaylamalısınız.',
							}}
							label={
								<>
									<button type="button" className="link" onClick={() => { openModal('agreement', { agreement: 'consent' }); }}>Kişisel Verilerin Korunması Kanununa Dair Açık Rıza Metnini</button> okudum, onaylıyorum.
								</>
							}
							defaultValue="1"
							checked={userFormData.agreement_kvkk ? true : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									agreement_kvkk: val ? true : false,
								})
							}}
							name="agreement_kvkk"
							id="register_agreement_kvkk" />

						<hr />

						<Input
							disabled={loading}
							type="checkbox"
							className="form-field multi-line"
							label={
								<>
									Önemli kampanyalardan haberdar olmak için e-posta yoluyla <button type="button" className="link" onClick={() => { openModal('agreement', { agreement: 'commercial' }); }}>burada</button> bulunan izin doğrultusunda ticari ileti gönderilmesini onaylıyorum.
								</>
							}
							defaultValue="1"
							checked={userFormData.notification_email ? true : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									notification_email: val ? true : false,
								})
							}}
							name="notification_email"
							id="register_notification_email" />

						<Input
							disabled={loading}
							type="checkbox"
							className="form-field multi-line"
							label={
								<>
									Önemli kampanyalardan haberdar olmak için SMS yoluyla <button type="button" className="link" onClick={() => { openModal('agreement', { agreement: 'commercial' }); }}>burada</button> bulunan izin doğrultusunda ticari ileti gönderilmesini onaylıyorum.
								</>
							}
							defaultValue="1"
							checked={userFormData.notification_sms ? true : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									notification_sms: val ? true : false,
								})
							}}
							name="notification_sms"
							id="register_notification_sms" />

						{/* <Input
							disabled={loading}
							type="checkbox"
							className="form-field multi-line"
							label={
								<>
									Önemli kampanyalardan haberdar olmak için telefon ile aranmak istiyorum.
								</>
							}
							defaultValue="1"
							checked={userFormData.notification_call ? true : undefined}
							onChange={(val) => {
								setUserFormData({
									...userFormData,
									notification_call: val ? true : false,
								})
							}}
							name="notification_call"
							id="register_notification_call" /> */}

						<div className="form-note">
							Bu sayfadaki bilgiler babymall.com.tr üyeliği için alınmaktadır. "Kişisel Verilerin Korunması Kanunu Aydınlatma Metni"ni <button type="button" className="link" onClick={() => { openModal('agreement', { agreement: 'kvkk' }); }}>buradan</button> okuyabilirsiniz.
						</div>

						<Btn
							loading={loading}
							disabled={loading}
							type="submit"
							className="form-field block primary big">
							Devam Et
						</Btn>
					</div>
				</Form>
			}

			{step === 4 &&
				<Form
					onSubmit={submitPin}
					className="register-step4 register-otp">
					<Btn
						className="otp-backbtn smaller outline"
						onClick={() => { setStep(3); }}
						icon="arrow-left">
						geri
					</Btn>

					<FormFeedback className="entry-error" data={feedbackData} />

					<div className="otp-message">Üyeliğinizi tamamlamak için <strong>{submittedUserData.pretty_mobile_number}</strong> numaralı telefonunuza gönderilen kodu girin.</div>

					<PinInput
						className="auth-pininput otp-pin"
						pin={pin}
						disabled={disablePin || loading}
						error={pinError}
						onChange={setPin}
						pinRef={pinTimerRef}
						onResend={sendCode}
						onComplete={() => { setPinError(false); submitPin(); }} />

					<Btn
						loading={loading}
						disabled={loading}
						type="submit"
						className="otp-submitbtn block primary big">
						Devam Et
					</Btn>
				</Form>
			}

			<p className="register-loginmessage">
				Daha önce Babymall.com.tr’ye üyeliğiniz var ise <Link className="link" href="login">Giriş Yap</Link> ekranından cep telefonunuza gelen onay kodu ile giriş yapabilirsiniz.
			</p>
		</div>
	)
}

export default Register